// 导入 Vue Router 相关函数
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

// 定义布局路由，这些路由将会嵌套在主布局组件内
export const layoutRoutes: Array<RouteRecordRaw> = [
  {
    path: '/index', // 定义 '/index' 路由
    name: '申请开票', // 路由的名称
    meta: {
      // 元数据，可以用于传递非响应式的数据
      title: 'index', // 页面标题
    },
    component: () => import('@/pages/index.vue'), // 动态导入对应的组件
  },

  {
    path: '/status', // 定义 '/status' 路由
    name: '开票状态', // 路由的名称
    meta: {
      // 元数据，可以用于传递非响应式的数据
      title: 'status', // 页面标题
    },
    component: () => import('@/pages/status.vue'), // 动态导入对应的组件
  },
  {
    path: '/orderPay/:groupId/:shopId/:orderId/:sign', // 定义 '/status' 路由
    name: '付款', // 路由的名称
    meta: {
      // 元数据，可以用于传递非响应式的数据
      title: '付款', // 页面标题
    },
    component: () => import('@/pages/orderPay/index.vue'), // 动态导入对应的组件
  },
  {
    path: '/paySuccess', // 定义 '/status' 路由
    name: '支付状态', // 路由的名称
    meta: {
      // 元数据，可以用于传递非响应式的数据
      title: '支付状态', // 页面标题
    },
    component: () => import('@/pages/orderPay/paySuccess.vue'), // 动态导入对应的组件
  },
  // {
  //   path: '/login', // 定义 '/login' 路由
  //   name: '登录', // 路由的名称
  //   meta: {
  //     // 元数据
  //     title: false, // 页面标题，这里设置为 false 表示不显示标题
  //   },
  //   component: () => import('@/pages/login/index.vue'), // 动态导入对应的组件
  // },
];

// 定义整个应用的路由配置
const routes: Array<RouteRecordRaw> = [
  {
    path: '/', // 根路由
    component: () => import('@/layout/index.vue'), // 主布局组件
    redirect: '/index', // 默认重定向到 '/login' 路由
    // 需要layout的页面
    children: layoutRoutes, // 子路由，嵌套在主布局组件内
  },
  // 可以在这里添加不需要布局的页面

  // 替代 Vue 2 中的 '*' 通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/' }, // 任何不符合上述定义的 URL 都会被重定向到 '/'
];

// 创建 Vue Router 实例
const router = createRouter({
  history: createWebHashHistory(), // 使用基于 hash 的历史记录模式
  // 如果需要使用 HTML5 History API，则使用 createWebHistory() 函数
  routes, // 应用上面定义的路由规则
});
// router.beforeEach((to, from, next) => {
//   if (to.meta && to.meta.title) {
//     document.title = to.meta.title;
//     const meta = document.querySelector('meta[name="description"]');
//     if (to.meta.meta && meta) {
//       meta.setAttribute('content', to.meta.meta.description);
//       const keywords = document.querySelector('meta[name="keywords"]');
//       if (keywords) {
//         keywords.setAttribute('content', to.meta.meta.keywords);
//       } else {
//         document.head.appendChild(createMeta('keywords', to.meta.meta.keywords));
//       }
//     }
//   }
//   next();
// });
// 导出 Vue Router 实例供其他模块使用
export default router;
