import App from './App.vue';
import router from '@/router';
import store from '@/store';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/index.less';
import '@/assets/less.css';
import 'lib-flexible';
const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app');
